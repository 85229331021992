import { Avatar, Box, Typography } from "@mui/material";
import remy from "../../static/images/sapir-logo.png";
import descriptionText from "./consts";
import Links from "../Links";
// import "./styles.css";

const LandingPage = () => {
  return (
    <Box
      display={"flex"}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      marginTop={"50px"}
      flexDirection={"column"}
      gap={"10px"}
    >
      <Avatar
        sx={{ height: "220px", width: "220px" }}
        alt="Sapir makeup and hair"
        src={remy}
      />
      <Typography fontFamily={'"Secular One", sans-serif;'}>
        {descriptionText}
      </Typography>
      <Links />
    </Box>
  );
};

export default LandingPage;
