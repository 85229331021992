import { Box, IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const Links = () => {
  return (
    <Box
      display={"flex"}
      gap={"20px"}
      maxWidth={"150px"}
      justifyContent={"center"}
      flexWrap={"wrap"}
    >
      <a href="https://www.WordPress.com" target="_blank" rel="noreferrer">
        <IconButton>
          <InstagramIcon fontSize={"large"} />
        </IconButton>
      </a>
      <a href="https://www.WordPress.com" target="_blank" rel="noreferrer">
        <IconButton>
          <WhatsAppIcon fontSize={"large"} />
        </IconButton>
      </a>
      <a href="https://www.WordPress.com" target="_blank" rel="noreferrer">
        <IconButton>
          <InstagramIcon fontSize={"large"} />
        </IconButton>
      </a>
      <a href="https://www.WordPress.com" target="_blank" rel="noreferrer">
        <IconButton>
          <InstagramIcon fontSize={"large"} />
        </IconButton>
      </a>
    </Box>
  );
};

export default Links;
